<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <!-- Close Modal -->
        <div v-if="positionChecker() === 'stepClose'">
          <div class="modal-header mb-2">
            <img
              src="../../../public/images/astal-logo.png"
              alt=""
              width="120"
              class="mx-auto"
            />
          </div>
          <div class="modal-body">
            <div class="text-center">
              <h5 style="color: black; font-weight: 600">
                Apakah anda yakin ingin keluar?
              </h5>
              <p>Anda harus memulai ulang verifikasi</p>
            </div>
            <img
              width="100"
              height="100"
              src="https://img.icons8.com/isometric/100/exit.png"
              alt="exit"
              class="my-3"
            />
            <div class="mb-4">
              <button @click="$emit('close')" class="form_button_danger mr-2">
                Keluar
              </button>
              <button @click="backStepPosition" class="form_button_border">
                Lanjutkan verifikasi
              </button>
            </div>
          </div>
        </div>

        <!-- Finish Verification -->
        <div v-else-if="positionChecker() === 'stepFinish'">
          <div class="modal-header mb-2">
            <img
              src="../../../public/images/astal-logo.png"
              alt=""
              width="120"
              class="mx-auto"
            />
          </div>

          <div class="modal-body px-4">
            <div class="text-center">
              <h5 style="color: black; font-weight: 600">Verifikasi Selesai</h5>
              <p>Terima kasih telah menyelesaikan proses verifikasi.</p>
            </div>
            
            <img
              width="100"
              height="100"
              src="https://img.icons8.com/isometric/100/checked.png"
              alt="completed"
              class="my-3"
            />

            <div class="info_alert w-100 mb-3">
              <h6 class="text-center mb-3">Hasil Verifikasi Biometrik</h6>

              <div class="mb-3">
                <strong>Probability: {{ biometricProbabilityScore }}</strong>
                <div class="score_bar_container mt-1">
                  <div
                    class="score_bar"
                    :style="{
                      width: biometricProbabilityScore,
                    }"
                  ></div>
                </div>
              </div>

              <div>
                <strong>Quality: {{ biometricQualityScore }}</strong>
                <div class="score_bar_container mt-1">
                  <div
                    class="score_bar"
                    :style="{
                      width: biometricQualityScore,
                    }"
                  ></div>
                </div>
              </div>
            </div>

            <div class="mb-4">
              <button
                @click="navigateToAgreement"
                class="form_button_secondary"
              >
                Lengkapi data diri
              </button>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="modal-header">
            <!-- invisible element -->
            <div class="close_button" style="opacity: 0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M18 6 6 18"></path>
                <path d="m6 6 12 12"></path>
              </svg>
            </div>

            <img
              src="../../../public/images/astal-logo.png"
              alt=""
              width="120"
            />

            <button @click="nextStepPosition('stepClose')" class="close_button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M18 6 6 18"></path>
                <path d="m6 6 12 12"></path>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <!-- Agreement -->
            <div v-if="positionChecker() === 'stepAgreement'" class="w-100">
              <div class="body_content">
                <div class="py-4">
                  <div
                    class="d-flex justify-content-center align-items-center mb-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="mr-3"
                    >
                      <path
                        d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"
                      ></path>
                      <path d="M14 2v4a2 2 0 0 0 2 2h4"></path>
                    </svg>
                    <div>
                      Unggah foto dokumen yang membuktikan identitas Anda
                    </div>
                  </div>
                  <div class="d-flex justify-content-center align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="mr-3"
                    >
                      <path
                        d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z"
                      ></path>
                      <circle cx="12" cy="13" r="3"></circle>
                    </svg>
                    <div>Ambil selfie</div>
                  </div>
                </div>
                <div class="my-3 px-3" style="height: 4rem; overflow-y: auto">
                  <p style="font-size: 13px">
                    Dengan mengeklik "Mulai", saya mengizinkan Perusahaan dan
                    penyedia layanannya, IDMeta, untuk memperoleh dan
                    mengungkapkan hasil pindaian geometri wajah dan barcode ID
                    saya untuk tujuan verifikasi identitas saya sesuai dengan
                    Kebijakan Privasi Perusahaan dan IDMeta dan untuk
                    meningkatkan dan memperbarui produk atau layanan IDMeta
                    (termasuk algoritmanya). Perusahaan dan IDMeta akan
                    menyimpan data biometrik untuk jangka waktu tertentu
                    sebagaimana diwajibkan oleh hukum atau selama diperlukan
                    untuk tujuan pengumpulannya.
                  </p>
                </div>
              </div>
              <div class="body_footer">
                <button
                  @click="createVerificationId()"
                  class="form_button w-100"
                  :disabled="isLoading"
                >
                  <span
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                  <span v-else>Berikutnya</span>
                </button>
              </div>
            </div>

            <!-- Step one (Document Verification) -->
            <div v-if="positionChecker() === 'stepDocument'" class="w-100">
              <div class="body_content">
                <div class="pr-4">
                  <div class="my-2 px-2">
                    <div>
                      <div
                        v-if="stepDocumentFormPosition === 'captureFrontSide'"
                      >
                        <label
                          >Ambil Sisi Depan KTP
                          <span class="text-danger"> *</span></label
                        >
                        <div
                          class="img-thumbnail position-relative"
                          :class="!videoStream && 'cursor-pointer'"
                        >
                          <div
                            v-if="!videoStream && !isCameraLoading"
                            class="center_element"
                          >
                            <h6>Klik untuk memulai kamera</h6>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="mr-3"
                            >
                              <path
                                d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z"
                              ></path>
                              <circle cx="12" cy="13" r="3"></circle>
                            </svg>
                          </div>

                          <div v-if="isCameraLoading" class="center_element">
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                            ></span>
                          </div>

                          <video
                            @click="
                              async () => {
                                await cameraSidesChecker();
                                startVideoStream('environment');
                              }
                            "
                            ref="videoElement"
                            autoplay=""
                            playsinline=""
                            width="100%"
                          ></video>
                        </div>

                        <div class="d-flex justify-content-center mb-2">
                          <button
                            v-if="availableAllCameraSides"
                            @click="
                              () => {
                                stopVideoStream();
                                switchCamera();
                                startVideoStream();
                              }
                            "
                            class="form_button_border"
                            style="padding: 6px 8px"
                          >
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                              />
                            </svg>
                          </button>
                        </div>

                        <button
                          @click="
                            () => {
                              captureImage('stepDocumentcapturedFrontImage');
                              stopVideoStream();
                              stepDocumentFormPosition = 'captureBackSide';
                            }
                          "
                          class="form_button"
                          :disabled="!videoStream"
                        >
                          Ambil Sisi Depan
                        </button>
                      </div>

                      <div
                        v-else-if="
                          stepDocumentFormPosition === 'captureBackSide'
                        "
                      >
                        <label
                          >Ambil Sisi Belakang KTP
                          <span class="text-danger"> *</span></label
                        >
                        <div
                          class="img-thumbnail position-relative"
                          :class="!videoStream && 'cursor-pointer'"
                        >
                          <div
                            v-if="!videoStream && !isCameraLoading"
                            class="center_element"
                          >
                            <h6>Klik untuk memulai kamera</h6>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="mr-3"
                            >
                              <path
                                d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z"
                              ></path>
                              <circle cx="12" cy="13" r="3"></circle>
                            </svg>
                          </div>

                          <div v-if="isCameraLoading" class="center_element">
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                            ></span>
                          </div>

                          <video
                            @click="startVideoStream('environment')"
                            ref="videoElement"
                            autoplay=""
                            playsinline=""
                            width="100%"
                          ></video>
                        </div>

                        <div class="d-flex justify-content-center mb-2">
                          <button
                            v-if="availableAllCameraSides"
                            @click="
                              () => {
                                stopVideoStream();
                                switchCamera();
                                startVideoStream();
                              }
                            "
                            class="form_button_border"
                            style="padding: 6px 8px"
                          >
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                              />
                            </svg>
                          </button>
                        </div>

                        <div class="d-flex justify-content-between">
                          <button
                            @click="
                              () => {
                                captureImage('stepDocumentcapturedBackImage');
                                stopVideoStream();
                                stepDocumentFormPosition =
                                  'frontAndBackSideResult';
                              }
                            "
                            class="form_button mr-2"
                            :disabled="!videoStream"
                          >
                            Ambil Sisi Belakang
                          </button>

                          <!-- <button
                          @click="stepDocumentFormPosition = 'skipBackSide'"
                          class="form_button_secondary"
                        >
                          Lewati Sisi Belakang
                        </button> -->
                        </div>
                      </div>

                      <div
                        v-else-if="
                          stepDocumentFormPosition === 'frontAndBackSideResult'
                        "
                      >
                        <div class="mb-3">
                          <label>Gambar Sisi Depan</label>
                          <img
                            :src="stepDocumentcapturedFrontImage"
                            alt=""
                            class="img-thumbnail"
                          />
                        </div>

                        <div>
                          <label>Gambar Sisi Belakang</label>
                          <img
                            :src="stepDocumentcapturedBackImage"
                            alt=""
                            class="img-thumbnail"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="
                        stepDocumentFormPosition !== 'frontAndBackSideResult'
                      "
                      class="info_alert mt-3"
                    >
                      <p>
                        Silahkan unggah foto <i>KTP</i> Anda dengan jelas dan
                        lengkap. Pastikan informasi pada dokumen terlihat jelas
                        dan tidak terpotong. Unggah foto bagian depan dan
                        belakang <i>KTP</i>.
                      </p>
                    </div>

                    <!-- <div
                      v-else-if="stepDocumentFormPosition === 'skipBackSide'"
                    >
                      <label>Gambar Sisi Depan</label>
                      <img
                        :src="stepDocumentcapturedFrontImage"
                        alt=""
                        class="img-thumbnail"
                      />
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="body_footer">
                <button
                  @click="postDocumentVerification()"
                  class="form_button w-100"
                  :disabled="
                    !stepDocumentcapturedFrontImage ||
                    !stepDocumentcapturedBackImage ||
                    isLoading
                  "
                >
                  <span
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                  <span v-else>Berikutnya</span>
                </button>
                <p class="mt-3 mb-0" style="font-size: 12px; color: #4b556380">
                  Verifikasi Dokumen - Langkah 1 dari 2
                </p>
              </div>
            </div>

            <!-- Step two (Biometric Verification) -->
            <div v-if="positionChecker() === 'stepBiometric'" class="w-100">
              <div class="body_content">
                <div v-if="stepBiometricFormPosition === 'captureImage'">
                  <div
                    class="img-thumbnail position-relative"
                    :class="!videoStream && 'cursor-pointer'"
                  >
                    <div
                      v-if="!videoStream && !isCameraLoading"
                      class="center_element"
                    >
                      <h6>Klik untuk memulai kamera</h6>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="mr-3"
                      >
                        <path
                          d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z"
                        ></path>
                        <circle cx="12" cy="13" r="3"></circle>
                      </svg>
                    </div>

                    <div v-if="isCameraLoading" class="center_element">
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                      ></span>
                    </div>

                    <video
                      @click="startVideoStream('user')"
                      ref="videoElement"
                      autoplay=""
                      playsinline=""
                      width="100%"
                    ></video>
                  </div>

                  <div class="d-flex justify-content-center mb-2">
                    <button
                      v-if="availableAllCameraSides"
                      @click="
                        () => {
                          stopVideoStream();
                          switchCamera();
                          startVideoStream();
                        }
                      "
                      class="form_button_border"
                      style="padding: 6px 8px"
                    >
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                        />
                      </svg>
                    </button>
                  </div>

                  <button
                    @click="
                      () => {
                        captureImage('stepBiometricCapturedImage');
                        stopVideoStream();
                        stepBiometricFormPosition = 'captureImageResult';
                      }
                    "
                    class="form_button"
                    :disabled="!videoStream"
                  >
                    Ambil
                  </button>

                  <div class="info_alert mt-3">
                    <p>
                      Silahkan lakukan pemindaian wajah Anda dengan pencahayaan
                      yang baik dan pastikan wajah terlihat jelas, tanpa ada
                      objek yang menghalangi.
                    </p>
                  </div>
                </div>

                <div v-if="stepBiometricFormPosition === 'captureImageResult'">
                  <img
                    :src="stepBiometricCapturedImage"
                    alt=""
                    class="img-thumbnail"
                  />
                  <button
                    @click="stepBiometricFormPosition = 'captureImage'"
                    class="form_button"
                  >
                    Ulangi
                  </button>
                </div>
              </div>
              <div class="body_footer">
                <button
                  @click="postBiometricVerification()"
                  class="form_button w-100"
                  :disabled="!stepBiometricCapturedImage || isLoading"
                >
                  <span
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                  <span v-else>Selesaikan Verifikasi</span>
                </button>
                <p class="mt-3 mb-0" style="font-size: 12px; color: #4b556380">
                  Verifikasi Biometrik - Langkah 2 dari 2
                </p>
              </div>
            </div>

            <!-- Verification Error -->
            <div v-if="positionChecker() === 'stepError'" class="w-100">
              <div class="body_content">
                <div class="error_alert">
                  <div class="mr-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" x2="12" y1="8" y2="12"></line>
                      <line x1="12" x2="12.01" y1="16" y2="16"></line>
                    </svg>
                  </div>
                  <div>
                    <h6>Error</h6>
                    <p>{{ errorMessage }}</p>
                  </div>
                </div>

                <div
                  v-if="errorMessage === biometricErrorMessages.lowProbability"
                  class="info_alert mt-3"
                >
                  <h6 class="text-center mb-3">Hasil Verifikasi Biometrik</h6>

                  <div class="mb-3">
                    <strong
                      >Probability: {{ biometricProbabilityScore }}</strong
                    >
                    <div class="score_bar_container mt-1">
                      <div
                        class="score_bar"
                        :style="{
                          width: biometricProbabilityScore,
                        }"
                      ></div>
                    </div>
                  </div>

                  <div>
                    <strong>Quality: {{ biometricQualityScore }}</strong>
                    <div class="score_bar_container mt-1">
                      <div
                        class="score_bar"
                        :style="{
                          width: biometricQualityScore,
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="body_footer">
                <button
                  @click="
                    () => {
                      resetState();
                      backStepPosition();
                    }
                  "
                  class="form_button_secondary align-self-start"
                >
                  Ulangi
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";

// applibs
import thestore from "@/applibs/thestore.js";

const toast = useToast();
export default {
  props: {
    show: Boolean,
  },

  data() {
    return {
      token: Cookies.get("jwt"),
      metaTemplateId: "",
      verificationId: "",

      stepPosition: ["stepAgreement"],
      stepDocumentFormPosition: "captureFrontSide",
      stepDocumentcapturedFrontImage: "",
      stepDocumentcapturedBackImage: "",
      stepDocumentcapturedFrontImageBlob: "",
      stepDocumentcapturedBackImageBlob: "",
      stepBiometricFormPosition: "captureImage",
      stepBiometricCapturedImage: "",
      stepBiometricCapturedImageBlob: "",

      documentErrorMessages: {
        invalidImage:
          "Gambar tidak valid, hanya JPG, JPEG, dan PNG yang diperbolehkan.",
        invalidId:
          "Ekstraksi gagal. Pastikan dokumen tersebut adalah KTP yang valid.",
      },
      biometricErrorMessages: {
        faceNotFound:
          "Tidak ada wajah yang terdeteksi. Pastikan wajah Anda terlihat sepenuhnya dan coba lagi.",
        faceTooSmall:
          "Wajah yang terdeteksi terlalu kecil. Silakan mendekat ke kamera dan coba lagi.",
        lowProbability:
          "Verifikasi wajah gagal. Silakan coba lagi dengan pencahayaan yang lebih baik atau sesuaikan posisi anda",
      },

      biometricProbabilityScore: "",
      biometricQualityScore: "",

      videoStream: null,
      availableAllCameraSides: false,
      facingMode: "user",
      errorMessage: "",
      isLoading: false,
      isCameraLoading: false,
    };
  },

  methods: {
    async getMetaConfig() {
      const res = await thestore.getConfig();
      this.metaTemplateId = res.metaTemplateId;
    },

    async createVerificationId() {
      this.isLoading = true;
      await this.getMetaConfig();

      try {
        const res = await thestore.postKycVerificationId(this.token, {
          template_id: this.metaTemplateId,
        });
        if (res.message === "Verification created successfully") {
          this.verificationId = res.verification.id;
          this.nextStepPosition("stepDocument");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Terjadi kesalahan");
      } finally {
        this.isLoading = false;
      }
    },

    async postDocumentVerification() {
      this.isLoading = true;

      const formData = new FormData();
      formData.append("template_id", this.metaTemplateId);
      formData.append("verification_id", this.verificationId);
      formData.append("document_verify", false);
      formData.append("returnFaceImage", false);
      formData.append("returnFullDocumentImage", false);
      formData.append("returnSignatureImage", false);
      formData.append(
        "imageFrontSide",
        this.stepDocumentcapturedFrontImageBlob,
        "image.png"
      );
      formData.append(
        "imageBackSide",
        this.stepDocumentcapturedBackImageBlob,
        "image.png"
      );

      try {
        const res = await thestore.postKycDocomentVerification(
          this.token,
          formData
        );

        if (res.message === "OK") this.nextStepPosition("stepBiometric");
      } catch (error) {
        if (error.response.status === 422) {
          const errorMessage = error.response.data.error.message;

          if (
            errorMessage === "Invalid Image, only JPG, JPEG and PNG are allowed"
          ) {
            this.errorMessage = this.documentErrorMessages.invalidImage;
          } else if (
            errorMessage ===
            "Extraction Failed. Please make sure the document is a valid ID."
          ) {
            this.errorMessage = this.documentErrorMessages.invalidId;
          } else {
            this.errorMessage = errorMessage;
          }

          this.nextStepPosition("stepError");
        } else {
          console.error("Error:", error);
        }
      } finally {
        this.isLoading = false;
      }
    },

    async postBiometricVerification() {
      this.isLoading = true;

      const formData = new FormData();
      formData.append("template_id", this.metaTemplateId);
      formData.append("verification_id", this.verificationId);
      formData.append(
        "image_file",
        this.stepBiometricCapturedImageBlob,
        "image.png"
      );

      try {
        const res = await thestore.postKycBiometricVerification(
          this.token,
          formData
        );

        if (res.message === "OK") this.nextStepPosition("stepFinish");
      } catch (error) {
        if (error.response.status === 422) {
          const errorMessage = error.response.data.error.response.message;

          if (errorMessage === "Face verification failed. FACE_NOT_FOUND") {
            this.errorMessage = this.biometricErrorMessages.faceNotFound;
          } else if (
            errorMessage === "Face verification failed. FACE_TOO_SMALL"
          ) {
            this.errorMessage = this.biometricErrorMessages.faceTooSmall;
          } else if (errorMessage === "Face verification failed") {
            const probabilityScore =
              error.response.data.error.response.probability;
            const qualityScore = error.response.data.error.response.quality;
            this.biometricProbabilityScore =
              this.calculateScore(probabilityScore);
            this.biometricQualityScore = this.calculateScore(qualityScore);
            this.errorMessage = this.biometricErrorMessages.lowProbability;
          } else {
            this.errorMessage = error.response.data.error.message;
          }

          this.nextStepPosition("stepError");
        } else {
          console.error("Error:", error);
          toast.error("Terjadi kesalahan");
        }
      } finally {
        this.isLoading = false;
      }
    },

    nextStepPosition(nextStep) {
      this.stepPosition.push(nextStep);
    },

    backStepPosition() {
      this.stepPosition.pop();
    },

    positionChecker() {
      return this.stepPosition[this.stepPosition.length - 1];
    },

    resetState() {
      this.stepDocumentFormPosition = "captureFrontSide";
      this.stepDocumentcapturedFrontImage = "";
      this.stepDocumentcapturedBackImage = "";
      this.stepDocumentcapturedFrontImageBlob = "";
      this.stepDocumentcapturedBackImageBlob = "";
      this.stepBiometricFormPosition = "captureImage";
      this.stepBiometricCapturedImage = "";
      this.stepBiometricCapturedImageBlob = "";
      this.videoStream = null;
      this.errorMessage = "";

      if (this.positionChecker() !== "stepError")
        this.stepPosition = ["stepAgreement"];
    },

    async startVideoStream(facingMode) {
      if (this.isCameraLoading) return;

      this.isCameraLoading = true;

      try {
        if (!this.videoStream) {
          if (facingMode && this.availableAllCameraSides)
            this.facingMode = facingMode;

          const options = {
            audio: false,
            video: this.availableAllCameraSides
              ? {
                  facingMode: { exact: this.facingMode },
                  width: { ideal: 640 },
                  height: { ideal: 640 },
                }
              : {
                  width: { ideal: 640 },
                  height: { ideal: 640 },
                },
          };

          try {
            const videoElement = this.$refs.videoElement;
            this.videoStream = await navigator.mediaDevices.getUserMedia(
              options
            );
            videoElement.srcObject = this.videoStream;
          } catch (error) {
            console.error("Error accessing the camera: ", error);
            this.nextStepPosition("stepError");
            this.errorMessage = "Kamera tidak dapat diakses. Tolong sambungkan";
          }
        }
      } finally {
        this.isCameraLoading = false;
      }
    },

    stopVideoStream() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.videoStream = null;
      }
    },

    captureImage(imageStepState) {
      const video = this.$refs.videoElement;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this[imageStepState] = canvas.toDataURL("image/png");

      canvas.toBlob((blob) => {
        this[`${imageStepState}Blob`] = blob;
      }, "image/png");
    },

    async cameraSidesChecker() {
      const supports = navigator.mediaDevices.getSupportedConstraints();

      if (!supports["facingMode"]) return;

      let frontCamera = null;
      let rearCamera = null;

      try {
        frontCamera = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: { exact: "user" } },
        });
        frontCamera.getTracks().forEach((track) => track.stop());
        rearCamera = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: { exact: "environment" } },
        });
        rearCamera.getTracks().forEach((track) => track.stop());
      } catch (error) {
        console.error("Camera error: ", error);
      }

      if (frontCamera && rearCamera) this.availableAllCameraSides = true;
    },

    switchCamera() {
      this.facingMode = this.facingMode === "user" ? "environment" : "user";
    },

    // imageUploadHander(event, field) {
    //   const file = event.target.files[0];
    //   const validTypes = ["image/jpeg", "image/png"];
    //   const maxSize = 5 * 1024 * 1024; // 5MB

    //   if (file) {
    //     if (!validTypes.includes(file.type)) {
    //       toast.warning("Format file harus JPEG atau PNG");
    //       event.target.value = "";
    //       return;
    //     }

    //     if (file.size > maxSize) {
    //       toast.warning("Ukuran file tidak boleh lebih dari 5MB");
    //       event.target.value = "";
    //       return;
    //     }

    //     this[field] = file;
    //   }
    // },

    navigateToAgreement() {
      this.$router.push("/settings/agreement-sign");
    },

    calculateScore(score) {
      return (score * 100).toFixed(2) + "%";
    },
  },

  watch: {
    show(newVal) {
      if (!newVal) {
        this.stopVideoStream();
        this.resetState();
      }
    },
  },
};
</script>

<style scoped>
button {
  border: none;
  border-radius: 5px;
  outline: none;
  transition: all 0.2s ease;
  background-color: transparent;
}
button:disabled {
  background-color: #dedede;
  color: #6c6c6c;
}
button:disabled:hover {
  background-color: #dedede;
}

label {
  font-weight: 600;
  color: #09090b;
}

p {
  margin: 0;
}

input[type="file"] {
  padding: 8px 12px;
  border: 1px solid #e4e4e7;
  border-radius: 5px;
}
input[type="file"]:hover {
  cursor: pointer;
}
input[type="file"]::file-selector-button {
  border: none;
  font-weight: 600;
  background-color: transparent;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 100%;
  max-width: 26rem;
  margin: auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  display: flex;
  align-items: stretch;
  border: none;
}

.close_button {
  width: 2.5rem;
  height: 2.5rem;
}
.close_button:hover {
  background-color: #f4f4f5;
}
.close_button svg {
  width: 1rem;
  height: 1rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  color: #71717a;
}

.body_content {
  padding: 1.5rem;
  padding-top: 0;
  max-height: 300px;
  overflow-y: auto;
}

.body_footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  padding-top: 0;
}

.form_button {
  margin-top: 0.5rem;
  padding: 8px 16px;
  background-color: #18181b;
  color: #fafafa;
}
.form_button:hover {
  background-color: #2f2f31;
}

.form_button_secondary {
  margin-top: 0.5rem;
  padding: 8px 16px;
  background-color: #f4f4f5;
  color: #18181b;
}
.form_button_secondary:hover {
  background-color: #f6f6f7;
}

.form_button_danger {
  margin-top: 0.5rem;
  padding: 8px 16px;
  background-color: #ef4444;
  color: #fafafa;
}
.form_button_danger:hover {
  background-color: #f05656;
}

.form_button_border {
  margin-top: 0.5rem;
  padding: 8px 16px;
  border: 1px solid #e4e4e7;
}
.form_button_border:hover {
  background-color: #f4f4f5;
}

.error_alert {
  display: flex;
  padding: 16px;
  border: 1px solid #ef44447c;
  border-radius: 5px;
  color: #ef4444;
}
.error_alert svg {
  width: 1rem;
  height: 1rem;
}
.error_alert h6 {
  margin: 0;
  font-weight: 600;
}
.error_alert p {
  margin: 0;
}

.info_alert {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #ff440077;
  border-radius: 5px;
  color: #ff4500;
  background-color: #fff8e1;
}
.info_alert h6 {
  margin: 0;
  font-weight: 600;
}

.center_element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-wrap: nowrap;
  text-align: center;
}

.score_bar_container {
  width: 100%;
  height: 0.75rem;
  background-color: #f7941a34;
}
.score_bar {
  height: 100%;
  background-color: #f7931a;
}

.modal-default-button {
  float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media only screen and (max-width: 550px) {
  .body_content {
    max-height: 450px;
  }
}
</style>
